import request from '@/api/utils';

// eslint-disable-next-line import/prefer-default-export
export function getFarmTotal(formdata = {}, method = 'post') {
  const url = '/api/farmdata/total';
  return request.Api(url, formdata, method);
}

// eslint-disable-next-line import/prefer-default-export
export function getFarmDataMinute(formdata = {}, method = 'post') {
  const url = '/api/farmdata/minute';
  return request.Api(url, formdata, method);
}

// eslint-disable-next-line import/prefer-default-export
export function getFarmDataMinutes(formdata = {}, method = 'post') {
  const url = '/api/farmdata/minutes';
  return request.Api(url, formdata, method);
}

// eslint-disable-next-line import/prefer-default-export
export function getFarmMinuteGrup(formdata = {}, method = 'post') {
  const url = '/api/farmdata/minutegrup';
  return request.Api(url, formdata, method);
}
