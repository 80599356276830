import {
  getSantrallerMinuteGrup,
} from '@/api/Request/santraller';

import i18n from '@/locale';
import helper from '@/api/helper';

import {
  lineOptionsMulties,
  lineOptionEmpty,
} from '@/api/chart/options';
import store from '@/store';
import {
  _xAxis,
  setMinuteData,
  getMinuteTimeField,
  dataSort,
  setMinuteLable,
  emptyChart,
} from './utils';

export function setMinuteDataSort(_rawData, measureList) {
  const sortType = 'Hour';
  const res = dataSort(_rawData.data.data, sortType);
  const maxVal = {};
  const tempTimeData = {};

  res.forEach((item) => {
    const date = setMinuteLable(item);
    tempTimeData[date] = item;

    Object.keys(measureList).forEach((k) => {
      // eslint-disable-next-line radix
      const tmp1 = parseInt(item[measureList[k].value]);
      if (!maxVal[measureList[k].value]) {
        maxVal[measureList[k].value] = 0;
      }

      if (tmp1 > maxVal[measureList[k].value]) {
        maxVal[measureList[k].value] = tmp1;
      }
    });
  });

  return {
    data: tempTimeData,
    max: maxVal,
  };
}

export function setMinuteDataMultiSort(_rawData, measureList, key, value) {
  const sortType = 'Hour';
  const res = dataSort(_rawData.data.data, sortType);
  const maxVal = {};
  const tempTimeData = {};

  res.forEach((item) => {
    if (item[key] === value) {
      const date = setMinuteLable(item);

      if (item[key] in tempTimeData) {
        tempTimeData[item[key]][date] = item;
      } else {
        tempTimeData[item[key]] = {};
      }

      measureList.forEach((measure) => {
        // eslint-disable-next-line radix
        const tmp1 = parseInt(item[measure.value]);

        if (!maxVal[measure.value]) {
          maxVal[measure.value] = 0;
        }

        if (tmp1 > maxVal[measure.value]) {
          maxVal[measure.value] = tmp1;
        }
      });
    }
  });

  return {
    data: tempTimeData,
    max: maxVal,
  };
}

export function setMinuteChartOption(tempTimeData, measureList, chartTypeModel, colors, stepTime) {
  const series = [];
  const yAxis = [];
  let colorKey = 0;
  let offset = 0;

  const measures = [];
  const minute = 0;
  const hour = 5;
  let totalVal = {};
  let data = [];
  if ('max' in tempTimeData) {
    totalVal = tempTimeData.max;
  }
  if (helper.isObjectKeysExist(tempTimeData, 'max')) {
    totalVal = tempTimeData.max;
  }
  if (helper.isObjectKeysExist(tempTimeData, 'data')) {
    data = tempTimeData.data;
  }

  measureList.forEach((measure) => {
    measures.push(`${measure.value} ${measure.unit}`);

    const tempData = setMinuteData(hour, minute, data, measure, stepTime);

    if (chartTypeModel === 'line2') {
      // eslint-disable-next-line no-param-reassign
      chartTypeModel = 'line';
    }

    const obj = {
      name: `${i18n.t(measure.value)}(${measure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    let position = 'left';
    if (colorKey > 0) {
      position = 'right';
      obj.yAxisIndex = colorKey;
    }

    if (colorKey > 1) {
      offset += 110;
    }
    if (chartTypeModel === 'line2') {
      obj.areaStyle = {};
    }

    let max = 0;
    // eslint-disable-next-line camelcase
    if (helper.isObjectKeysExist(totalVal, measure.value)) {
      // eslint-disable-next-line radix
      max = parseInt(totalVal[measure.value] * (120 / 100));
    }

    const xAxis = _xAxis(measure, offset, position, colors[colorKey], 0, max);
    if (colorKey > 1) {
      xAxis.show = false;
    }

    yAxis.push(xAxis);
    series.push(obj);
    colorKey += 1;
  });

  return {
    yAxis,
    series,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function setChartMinute(formdata, measureList, text, chartTypeModel) {
  return getSantrallerMinuteGrup(formdata).then((_rawData) => setChartMinuteGraph(_rawData,
    measureList, text, chartTypeModel));
}

export function setFarmChartMinute(formdata, measureList, text, chartTypeModel) {
  return getSantrallerMinuteGrup(formdata).then((_rawData) => setChartMinuteGraph(_rawData,
    measureList, text, chartTypeModel));
}

// eslint-disable-next-line import/prefer-default-export
export function setChartMinuteGraph(_rawData, measureList, text, chartTypeModel) {
  if (_rawData.status !== 200 || _rawData.data.success === 'false') {
    return lineOptionEmpty();
  }
  const tempTimeData = setMinuteDataSort(_rawData, measureList);

  const {
    selectedFarm,
  } = store.getters;
  const colors = ['#5470C6', '#91CC75', '#EE6666'];
  const stepTime = helper.getFarmStep(selectedFarm.prefix);
  const temp = setMinuteChartOption(tempTimeData, measureList, chartTypeModel, colors, stepTime);
  const minute = 0;
  const hour = 5;
  const timeField = getMinuteTimeField(hour, minute, stepTime);
  return lineOptionsMulties(text, measureList, timeField, temp.series,
    colors, temp.yAxis);
}
