import * as d3 from 'd3';


// eslint-disable-next-line import/prefer-default-export
export function d3jsChartComponent(
  parameter = {},
  data = {},
  mousemove,
  toolTip,
) {
  const svg = d3
    .select(`#${parameter.chartId}`)
    .append('svg')
    .attr('width', parameter.width + 150)
    .attr('height', parameter.height + 50)
    .append('g')
    .attr('transform', parameter.transform);

  const myGroups = d3.map(data, (d) => d[parameter.chartXkey]).keys();
  const myVars = d3.map(data, (d) => d[parameter.chartYkey]).keys();

  const x = d3
    .scaleBand()
    .range([0, parameter.width])
    .domain(myGroups)
    .padding(0.05);
  // Build Y scales and axis:
  const y = d3
    .scaleBand()
    .range([parameter.height, 0])
    .domain(myVars)
    .padding(0.05);

  svg
    .append('g')
    .style('font-size', 12)
    .attr('transform', `translate(0,${parameter.height})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .style('text-anchor', 'end')
    .attr('dx', '-.8em')
    .attr('dy', '-.5em')
    .attr('transform', 'rotate(-85)');

  svg
    .append('g')
    .style('font-size', 12)
    .call(d3.axisLeft(y).tickSize(0))
    .select('.domain');

  const mouseover = function (d) {
    toolTip.transition().duration(1).style('opacity', 0.9);
  };

  const mouseleave = function (d) {
    toolTip.style('opacity', 0);
    // d3.select(this).style('stroke', 'none').style('opacity', 0.9);
  };

  svg
    .selectAll()
    .data(data, (d) => `${d[parameter.chartXkey]}:${d[parameter.chartYkey]}`)
    .enter()
    .append('rect')
    .attr('font-size', 11)
    .attr('x', (d) => x(d[parameter.chartXkey]))
    .attr('y', (d) => y(d[parameter.chartYkey]))
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('width', x.bandwidth())
    .attr('height', y.bandwidth())
    .style('fill', (d) => d.color)
    .style('stroke-width', 4)
    .style('stroke', 'none')
    .style('opacity', 0.8)
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseleave', mouseleave);
}

export function d3jsChartToolTip(parameter = {}) {
  const toolTip = d3
    .select(`#${parameter.chartId}`)
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('text-align', 'center')
    .style('padding', '30px')
    .style('background', '#24b1d1')
    .style('border', '0px')
    .style('border-radius', '8px')
    .style('opacity', 0);

  return {
    toolTip,
  };
}
