<template>
  <v-dialog
    v-model="dialog"
    :width="windowsWidth"
    height="530px"
    scrollable
    max-height="530px"
    dense
  >
    <v-card height="530px">
      <v-card-title class="text-center text-h5 mb-0 pb-0 pt-1 mt-0">
        <span class="ml-2 text-h5"> {{ farm.companyName }} /</span>
        <span class="ml-2 text-h5"> {{ farm.farmName }}</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <Error ref="farmdata" :farmdata="farmdata" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Error from "@/views/components/Notification/Error.vue";
export default {
  components: {
    Error
  },
  props: ["dialog", "farm"],
  data: () => ({
    farmdata: {
      windowsWidth: `${document.documentElement.clientWidth}px`
    },
    windowsWidth: `${document.documentElement.clientWidth - 400}px`
  }),
  watch: {
    dialog() {
      this.farmdata = this.farm;
      this.farmdata.windowsWidth = `${(document.documentElement.clientWidth * 75) / 100}px`;
      if (!this.dialog) {
        this.$emit("alarmdialog");
      }
    }
  },
  methods: {
    setKey(item) {
      this.selected = item.value;
    }
  }
};
</script>
