<template>
  <div>
    <div class="col-md-12 mr-0 pr-0 pt-0 mb-0">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="desserts"
        :items-per-page="itemPerPage"
        class="elevation-1"
      >
        <template #body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            :disabled="!allowDrag"
            :move="onMoveCallback"
            :clone="onCloneCallback"
            @end="onDropCallback"
          >
            <data-table-row-handler
              v-for="(item, index) in props.items"
              :key="index"
              :item="item"
              :headers="activeHeaders"
              :item-class="getClass(item)"
            >
              <template v-slot:item.NominalPlantPower="{ item }">
                <span class="mr-3 d-flex">
                  {{ item.NominalPlantPower }}
                </span>
              </template>

              <template v-slot:item.temp="{ item }">
                <v-col class="mb-0 pb-0 d-flex pt-0 mt-0" cols="6">
                  <v-img :src="item.icon" alt="Sunny image" width="30" />
                  <span class="mr-3 text-h5"> {{ item.temp }}&deg;C </span>
                </v-col>
              </template>
              <template v-slot:item.prefix="{ item }">
                <v-btn color="blue darken-1" text @click="farmGraphic(item, 'screen')">
                  Anlık
                </v-btn>

                <v-btn color="blue darken-1" text @click="farmGraphic(item, 'alarm')">
                  Hata
                </v-btn>
                <v-btn color="blue darken-1" text @click="farmOpen(item, 'detail')">
                  Detay
                </v-btn>
                <v-btn color="blue darken-1" text @click="farmOpen(item, 'setting')">
                  Firma Yönetim
                </v-btn>
                <v-btn color="blue darken-1" text @click="farmOpen(item, 'ayar')">
                  Saha Yönetim
                </v-btn>
              </template>
            </data-table-row-handler>
          </draggable>
        </template>
      </v-data-table>
    </div>
    <Screen :dialog="graphicdialog" :farm="selectedfarm" @screendialog="screendialogclose" />
    <Alarm :dialog="errordialog" :farm="selectedfarm" @alarmdialog="alarmdialogclose" />
  </div>
</template>

<script>
import { setCompanyEdit } from "@/api/Request/company";
import { getFarmDataMinute } from "@/api/Request/farmdata";
import moment from "moment";
import helper from "@/api/helper";
import draggable from "vuedraggable";
import common from "@/api/component/common";
import Screen from "./Data/Screen.vue";
import Alarm from "./Data/Alarm.vue";

import DataTableRowHandler from "./DataTableRowHandler.vue";

const totalVal = {
  NominalPlantPower: 0,
  IstantaneousPower: 0,
  DailyEnergy: 0,
  TotalEnergy: 0,
  TheoreticalPower: 0,
  PerformanceRatio: 0,
  BarrelsofOil: 0,
  CO2Avoided: 0,
  EquivalentTrees: 0,
  connectTime: ""
};
export default {
  components: {
    Alarm,
    Screen,
    draggable,
    DataTableRowHandler
  },
  data: () => ({
    company: {
      companyName: "",
      adress: "",
      status: ""
    },
    farmEdit: "false",
    dialog: false,
    itemPerPage: 10,
    desserts: [],
    headers: [],
    companyData: {},
    selectedfarm: {},
    selectedMeasure: "",
    graphicdialog: false,
    errordialog: false,
    allowDrag: true,
    selected: []
  }),
  computed: {
    activeHeaders() {
      return this.headers.filter(h => {
        if (!this.allowDrag && h.value === "lock") {
          return false;
        }
        return true;
      });
    }
  },
  created() {},
  mounted() {
    this.headers = [
      { text: "Şirket Adı", value: "companyName" },
      { text: "Saha Adı", value: "farmName" },
      {
        text: "Kurulu Güç(kw)",
        value: "NominalPlantPower",
        unit: "kw"
      },
      { text: "Günlük Enerji(kWh)", value: "DailyEnergy", unit: "kWh" },
      {
        text: "Anlık Güç(kWh)",
        value: "IstantaneousPower",
        unit: "kWh"
      },
      { text: "Hata", value: "error" },
      { text: "Hava durumu", value: "temp" },
      { text: "Baglantı Zamanı", value: "date" },
      { text: "", value: "prefix" }
    ];

    this.getCompany();
  },
  methods: {
    screendialogclose() {
      this.graphicdialog = false;
    },
    alarmdialogclose() {
      this.errordialog = false;
    },
    getClass(item) {
      return "cal-low text-aling-left";
    },
    onCloneCallback(item) {
      const cloneMe = JSON.parse(JSON.stringify(item));

      return cloneMe;
    },
    onMoveCallback(evt, originalEvent) {
      const item = evt.draggedContext.element;
      if (item.locked) {
        return false;
      }

      return true;
    },
    onDropCallback(evt, originalEvent) {
      // console.log("onDropCallback");
    },
    farmGraphic(item, type) {
      if (type === "screen") {
        this.selectedfarm = item;
        this.graphicdialog = true;
      }

      if (type === "alarm") {
        this.selectedfarm = item;
        this.errordialog = true;
      }
    },
    getCompany() {
      const params = {
        condiniton: {
          status: 'Active',
          date: moment().format("YYYY-MM-DD"),
          // eslint-disable-next-line radix
          hour: parseInt(
            moment()
              .add(-10, "minute")
              .format("H")
          )
        }
      };

      getFarmDataMinute(params).then(res => {
        const rest = [];
        this.desserts = res.data.data;

        Object.keys(res.data.data).forEach(i => {
          rest.push(this.setValue(res.data.data[i]));
        });
        this.desserts = rest;

        this.getTotalStat(res);
      });
    },
    setValue(data) {
      if ("Hour" in data) {
        const hour = data.Hour < 10 ? `0${data.Hour}` : data.Hour;
        const minute = data.MinuteGrup < 10 ? `0${data.MinuteGrup}` : data.MinuteGrup;

        data.date = `${data.Time} ${hour}:${minute}:00`;
      } else {
        data.date = "";
      }
      data.temp = "";
      data.icon = "";
      if ("weather" in data) {
        data.temp = parseInt(data.weather[0].temp);
        data.icon = this.setTest(data.weather[0]);
      }

      this.setParameters(data);
      return { ...data, ...totalVal };
    },

    setTest(data) {
      const temp_description = data.weather[0].description;
      const { date } = data;
      const hour = parseInt(data.Hour);
      let hh = "";
      hour < 10 ? (hh = `0${hour}:00`) : (hh = `${hour}:00`);

      const sunrise_s = data.sunrise;
      const sunset_s = data.sunset;

      const sunrise = moment(sunrise_s * 1000).format("YYYY-MM-DD HH:mm:ss");
      const sunset = moment(sunset_s * 1000).format("YYYY-MM-DD HH:mm:ss");
      let type = "day";
      if (date < sunrise) {
        type = "night";
      }
      if (date > sunset) {
        type = "night";
      }

      return common.weaterIcons(type, temp_description);
    },

    getTotalStat(result) {
      if (result.data.success === "true") {
        result.data.data.forEach(data => {
          this.setParameters(data);
        });
      }
      totalVal.TotalEnergy = helper.kwhToMwh(totalVal.TotalEnergy);
      this.$emit("totalStats", totalVal);
    },

    setParameters(data) {
      Object.keys(totalVal).forEach(m => {
        let v = 0;

        if (typeof data[m] === "object") {
          if ("value" in data[m]) {
            v = data[m].value;
          }
        } else {
          v = data[m];
        }

        try {
          totalVal[m] = helper.setFixed(v, 1);
        } catch (e) {
          totalVal[m] = 0;
        }
      });
    },

    farmOpen(item, event) {
      const storage = window.localStorage;
      const temp = {
        companyCode: item.companyCode,
        prefix: item.prefix
      };
      const encoded = btoa(JSON.stringify(temp));
      this.$store.dispatch("setCompany", temp);
      storage.setItem("user_company", encoded);

      this.homePage(item, event);
    },
    async homePage(item, event) {
      this.selectUser(item);

      if (event === "detail") {
        window.location.href = "/dashboard";
      }

      if (event === "setting") {
        window.location.href = "/admin/setting/info";
      }
      if (event === "ayar") {
        window.location.href = "/admin/station/company";
      }
    },
    selectUser(item) {
      const storage = window.localStorage;
      storage.setItem("selectedprefix", window.btoa(item.prefix));

      const temp = {
        companyCode: item.companyCode,
        prefix: item.prefix
      };

      this.$store.dispatch("setSelectedFarm", temp);
      storage.setItem("user_company", window.btoa(JSON.stringify(temp)));
    },
    editFarm() {
      const params = {
        condiniton: {
          companyCode: this.company.companyCode
        },
        data: this.company
      };
      setCompanyEdit(params).then(res => {
        // console.log(res);
        this.getCompany();
      });
    }
  }
};
</script>

<style>
.company {
  height: 100%;
  min-height: 610px;
}
td div {
  text-align: left !important;
}
 
</style>
