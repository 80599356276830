<template>
  <v-dialog
    v-model="dialog"
    :width="windowsWidth"
    height="530px"
    scrollable
    max-height="530px"
    dense
  >
    <v-card height="530px">
      <v-card-text>
        <div class="d-flex">
          <div class="col-6">
            <span class="ml-2 text-h5"> {{ farm.companyName }} /</span>
            <span class="ml-2 text-h5"> {{ farm.farmName }}</span>
          </div>

          <v-spacer />
          <v-tabs v-model="toggle_exclusive" color="primary" right height="30px">
            <v-tab
              dense
              v-for="(btn, id) in toggleList"
              :key="btn.value"
              :href="`#tab-${btn.value}`"
            >
              {{ btn.text }}
            </v-tab>
          </v-tabs>
        </div>

        <v-divider />

        <v-tabs-items v-model="toggle_exclusive">
          <v-tab-item value="tab-farm">
            <DailyMinuteData ref="farmdata" :farmdata="farmdata" />
          </v-tab-item>
          <v-tab-item value="tab-inverter">
            <SelectedFarmInverter ref="farmdata" :farmdata="farmdata" />
          </v-tab-item>

          <v-tab-item value="tab-string">
            <SelectedFarmStringbox ref="farmdata" :farmdata="farmdata" />
          </v-tab-item>

          <v-tab-item value="tab-sensor">
            <SelectedFarmSensor ref="farmdata" :farmdata="farmdata" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DailyMinuteData from "@/views/components/Dashboard/DailyMinuteData.vue";
import SelectedFarmInverter from "@/views/components/Inverter/SelectedFarmInverter.vue";
import SelectedFarmStringbox from "@/views/components/Stringbox/SelectedFarmStringbox.vue";
import SelectedFarmSensor from "@/views/components/Sensor/SelectedFarmSensor.vue";

export default {
  components: {
    SelectedFarmInverter,
    DailyMinuteData,
    SelectedFarmStringbox,
    SelectedFarmSensor
  },
  props: ["dialog", "farm"],
  data: () => ({
    toggle_exclusive: 0,
    selected: "farm",
    toggleList: [
      { text: "Saha", value: "farm" },
      { text: "İnverter", value: "inverter" },
      { text: "String", value: "string" },
      { text: "Sensör", value: "sensor" },
      { text: "Osos", value: "osos" }
    ],
    farmdata: {
      windowsWidth: `${document.documentElement.clientWidth}px`
    },
    windowsWidth: `${document.documentElement.clientWidth - 400}px`
  }),
  watch: {
    dialog() {
      this.farmdata = this.farm;
      this.farmdata.windowsWidth = `${(document.documentElement.clientWidth * 75) / 100}px`;

      if (!this.dialog) {
        this.$emit("screendialog");
      }
    }
  },
  methods: {
    setKey(item) {
      this.selected = item.value;
    }
  }
};
</script>
