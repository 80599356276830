<template>
  <v-col cols="12" class="mt-0 mb-0 pt-2">
    <v-card class="mr-1">
      <v-col cols="12" class="pb-0 pt-0 mt-0 mb-0 pr-3">
        <v-row>
          <v-spacer />

          <v-col cols="2" class="pr-3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="startDate" no-title scrollable>
                <v-spacer />
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(startDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" class="pr-3">
            <v-select v-model="chartTypeModel" :items="chartType" label="Grafik" hide-details />
          </v-col>
          <div class="pr-3 text-right pt-4">
            <v-btn color="primary" @click="getSantralDataChart">
              Filtrele
            </v-btn>
          </div>
        </v-row>
      </v-col>

      <v-col class="col-12 mt-0 pt-0 pb-0 mb-0">
        <echart
          id="general-santral-power-daily-dash"
          class-name="widget-content"
          height="340px"
          min-width="900px"
          :width="windowsWidth"
          :chart-data="chartData"
        />
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import Echart from "@/views/components/Echart/Chart.vue";
import moment from "moment";
import i18n from "@/locale";
import { getFarmMinuteGrup } from "@/api/Request/farmdata";

import { setChartMinuteGraph } from "@/api/ChartComponent/chartComp";
export default {
  name: "Dashboard",
  components: {
    Echart
  },
  props: ["farmdata"],
  data: () => ({
    componentKey: 1,
    chartData: {},
    chartTypeModel: "line2",
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    chartType: ["line", "bar", "line2"],
    menu: false,
    modal: false,
    menu2: false,
    riseze: "false",
    measures: [],
    windowsWidth: ""
  }),
  computed: {},
  watch: {
    farmdata: {
      handler() {
        this.getSantralDataChart();
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.getSantralDataChart();
    }, 1000);
    setInterval(this.getSantralDataChart, 300000);
  },

  methods: {
    getSantralDataChart() {
      this.windowsWidth = `${document.documentElement.clientWidth - 250}px`;
      if (this.farmdata) {
        this.windowsWidth = this.farmdata.windowsWidth;
      }

      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      const measures = [];
      const measureList = [];
      // eslint-disable-next-line no-underscore-dangle
      const _measureList = [];
      const { statsmeasureList } = this.$store.getters;
      const mList = ["IstantaneousPower", "PerformanceRatio", "TheoreticalPower"];
      const tempmeasureList = [];
      Object.keys(statsmeasureList).forEach(i => {
        if (mList.indexOf(statsmeasureList[i].value) > -1) {
          const obj = {
            value: statsmeasureList[i].value,
            text: i18n.t(statsmeasureList[i].value),
            type: statsmeasureList[i].type,
            unit: statsmeasureList[i].unit
          };
          tempmeasureList.push(obj);
        }
      });

      const energy = ["DailyEnergy", "TotalEnergy"];

      tempmeasureList.forEach(item => {
        if (energy.indexOf(item.value) < 0) {
          measures.push(item.value);
          measureList.push(item);
        }
      });

      tempmeasureList.forEach(item => {
        if (energy.indexOf(item.value) < 0) {
          _measureList.push(item.value);
        }
      });
      console.log("measureList", measureList);

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          measures: _measureList
        }
      };

      if (this.farmdata) {
        params.condiniton.companyCode = this.farmdata.companyCode;
        params.condiniton.prefix = this.farmdata.prefix;
      }

      const chartText = "";
      const self = this;
      getFarmMinuteGrup(params).then(r => {
        console.log(r.data);

        this.chartData = setChartMinuteGraph(r, tempmeasureList, "", this.chartTypeModel);
        console.log(this.chartData);
      });

      /*  const tempData = setChartMinute(
        params,
        measureList,
        chartText,
        this.chartTypeModel,
      );
      tempData.then((res) => {
        self.chartData = res;
        self.componentKey += 1;
        self.forceRerender();
      }); */
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
