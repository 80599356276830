<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mx-auto  ">
          <v-col class="pl-0 pr-0 pb-1">
            <v-row>
              <v-spacer />
              <v-col
                cols="6"
                sm="2"
                md="2"
                class="pr-3"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field
                      v-model="startDate"
                      :label="$t('Tarih')"
                      readonly
                      outlined
                      v-bind="attrs"
                      dense
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>

              <v-col
                cols="6"
                sm="2"
                md="2"
                class="pr-3"
              >
                <v-select
                  v-model="alarmStatusModel"
                  :items="alarmStatus"
                  :label="$t('')"
                  multiple
                  outlined
                  dense
                  :placeholder="$t('Durum')"
                >
                  <template v-slot:selection="{item, index}">
                    <span
                      v-if="index === 0"
                      style="max-width: 120px"
                      class="d-inline-block text-truncate"
                    >{{ item.text }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ alarmStatusModel.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="2"
                md="2"
                class="pr-3"
              >
                <v-select
                  v-model="alarmTypeModel"
                  :items="alarmType"
                  :label="$t('')"
                  multiple
                  outlined
                  dense
                  :placeholder="$t('Tip')"
                >
                  <template v-slot:selection="{item, index}">
                    <span
                      v-if="index === 0"
                      style="max-width: 120px"
                      class="d-inline-block text-truncate"
                    >{{ item.text }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >(+{{ alarmTypeModel.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="6"
                sm="2"
                md="2"
                class="pr-3"
              >
                <v-select
                  v-model="santralModel"
                  :items="santralDataList"
                  :label="$t('santral')"
                  :placeholder="$t('')"
                  multiple
                  outlined
                  dense
                >
                  <template v-slot:selection="{item, index}">
                    <span
                      v-if="index === 0"
                      style="max-width: 120px"
                      class="d-inline-block text-truncate"
                    >{{ item }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ santralModel.length - 1 }})</span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="3"
                sm="1"
                md="1"
                class="pr-3 pt-3"
              >
                <v-btn
                  color="primary"
                  @click="getErrorTable"
                >
                  Filtrele
                </v-btn>
              </v-col>

              <v-col
                cols="3"
                sm="1"
                md="1"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      fab
                      dark
                      small
                      color="cyan"
                      v-on="on"
                      @click="exportDocument"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to Excel</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            class="fullgridtable pl-0 pr-0 pb-1"
          >
            <v-card class="elevation-0">
              <v-data-table
                :id="santralDataTable"
                dense
                :items-per-page.sync="itemsPerPage"
                :server-items-length="itemsLength"
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
                  showFirstLastPage: true,
                  itemsPerPageText: 'Limit'
                }"
                :options.sync="options"
                :headers="headers"
                :page.sync="currentPage"
                :loading="loading"
                loading-text="Yükleniyor... Lütfen bekleyiniz"
                :items="desserts"
                class=""
              >
                <template v-slot:item.status="{item}">
                  <v-chip
                    v-if="item.status == 'Active'"
                    color="green"
                  >
                    Aktif
                  </v-chip>
                  <v-chip
                    v-else
                    color="red"
                  >
                    Pasif
                  </v-chip>
                </template>
                <template v-slot:item.passivetime="{item}">
                  <span v-html="setTime(item.passivetime)" />
                </template>

                <template v-slot:item.finishMailSendDate="{item}">
                  <span v-html="setTime(item.finishMailSendDate)" />
                </template>

                <template v-slot:item.mailSendDate="{item}">
                  <span v-html="setTime(item.mailSendDate)" />
                </template>

                <template v-slot:item.updateTime="{item}">
                  <span v-html="setTime(item.updateTime)" />
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>
<script>
import moment from 'moment';
import ExportDocument from '@/views/components/Shared/ExportDocument.vue';
import { getNotifyErorr } from '@/api/Request/Notification';

export default {
  name: 'GeneralStatsHour',
  components: {
    ExportDocument,
  },
  props: ['farmdata'],
  data: () => ({
    santralModel: [],
    chartData: {},
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    menu: false,
    modal: false,
    menu2: false,
    itemsPerPage: 10,
    itemsLength: 10,
    headers: [],
    desserts: [],
    channelModel: 'STRINGBOX',
    channel: [
      { text: 'String', value: 'STRINGBOX' },
      { text: 'İnverter', value: 'INVERTER' },
    ],
    alarmType: [
      { text: 'Hata', value: 'Error' },
      { text: 'Uyarı', value: 'Warning' },
    ],
    alarmTypeModel: [],
    alarmStatusModel: [ ],
    alarmStatus: [
      { text: 'Aktif', value: 'Active' },
      { text: 'Pasif', value: 'Passive' },
    ],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: '',
    chartText: '',
    newData: 'true',
    currentPage: 1,
    loading: false,
    options: {},
    params: {
      condinition: {
        startDate: moment().format('YYYY-MM-DD'),
        finishDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        type: [],
        status: [],
        santral: [],
      },
    },
  }),
  computed: {
    santralDataList() {
      return this.$store.getters.santralDataList;
    },
  },
  watch: {
    reportTypeModel() {
      this.getErrorTable();
    },

    options: {
      handler() {
        this.loading = true;
        const {
          sortBy, sortDesc, page, itemsPerPage,
        } = this.options;

        if (sortBy.length > 0) {
          this.params.orderBys = [
            { fieldName: sortBy[0], sortType: sortDesc[0] },
          ];
        }
        this.params.skip = (page - 1) * itemsPerPage;
        this.params.limit = itemsPerPage;
        this.getErrorTable();
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    setTime(time) {
      if (time !== undefined) {
        return moment(time).format('HH:mm');
      }
    },
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    getErrorTable() {
      this.loading = true;

      this.finishDate = this.startDate;
      this.headers = [
        { text: 'Tip', value: 'type' },
        { text: 'Kod', value: 'code' },
        { text: 'Santral', value: 'santral' },
        { text: 'Durum', value: 'status' },
        { text: 'Cihaz', value: 'device' },
        { text: 'Alt Cihaz', value: 'subdevice' },
        { text: 'Açıklama', value: 'message' },
        { text: 'Başlangıç Tarih', value: 'createTime' },
        { text: 'İlk Mail', value: 'mailSendDate' },
        { text: 'Güncelleme Tarih', value: 'updateTime' },
        { text: 'Bitiş Tarih', value: 'passivetime' },
        { text: 'Bitiş Mail', value: 'finishMailSendDate' },
        { text: 'Hata süresi', value: 'lifetime' },
      ];

      this.finishDate = moment(this.startDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');

      this.params.condinition.startDate = this.startDate;
      this.params.condinition.finishDate = this.finishDate;
      if (this.santralModel.length > 0) {
        this.params.condinition.santral = this.santralModel;
      }
      if (this.alarmTypeModel.length > 0) {
        this.params.condinition.type = this.alarmTypeModel;
      }
      if (this.alarmStatusModel.length > 0) {
        this.params.condinition.status = this.alarmStatusModel;
      }
      if (this.farmdata) {
        this.params.prefix = this.farmdata.prefix;
        this.params.companyCode = this.farmdata.companyCode;
      }

      const self = this;

      getNotifyErorr(this.params).then((res) => {
        self.desserts = res.data.data;
        self.itemsLength = res.data.summary.totalRecordCount;
        self.forceRerender();
        self.loading = false;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.theme--dark
  .fullgridtable
  .theme--light.v-data-table
  tbody
  tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 0%, 100%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
