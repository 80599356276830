import i18n from '@/locale';
import store from '@/store';
import helper from '@/api/helper';

import {
  list,
} from '@/api/Request/sensor';
import {
  emptyChart,
  _xAxis,
  setMinuteData,
  getMinuteTimeField,
  setMinuteLable,
  dataSort,
} from '@/api/ChartComponent/utils';
import {
  lineOptionsMulties,
  lineOptionEmpty,
} from '@/api/chart/options';
import {
  setMinuteChartOption,
  setMinuteDataMultiSort,
} from '@/api/ChartComponent/chartComp';
import request from '../utils';
import chartOptions from '../chart/chart_options';
import common from '../component/common';

// eslint-disable-next-line import/prefer-default-export
export function getSensorBoXDailyChartOpt(formdata, sensorMeasure, measureList, text,
  chartTypeModel) {
  return list(formdata).then((_rawData) => {
    if (_rawData.status !== 200 || _rawData.data.success === 'false') {
      return lineOptionEmpty();
    }
    const colors = ['#5470C6', '#91CC75', '#EE6666'];
    const minute = 0;
    const hour = 5;
    const {
      selectedFarm,
    } = store.getters;
    const stepTime = helper.getFarmStep(selectedFarm.prefix);
    const timeField = getMinuteTimeField(hour, minute, stepTime);
    const yAxis = [];
    const series = [];
    Object.keys(measureList).forEach((item) => {
      const tepval = setMinuteDataMultiSort(_rawData, measureList[item], 'SENSOR', item);

      const temp = setMinuteChartOption(tepval.data, measureList[item], chartTypeModel,
        colors, stepTime, tepval.max);

      // eslint-disable-next-line no-shadow
      temp.yAxis.forEach((item) => {
        yAxis.push(item);
      });
      // eslint-disable-next-line no-shadow
      // eslint-disable-next-line no-shadow
      temp.series.forEach((item) => {
        series.push(item);
      });
    });

    return lineOptionsMulties(text, sensorMeasure, timeField, series,
      colors, yAxis);
  });
}

export function getSantralSensorChartOpts(_rawData, stepTime, measureList) {
  if (_rawData.status !== 200 || _rawData.data.success === 'false') {
    return emptyChart('text', measureList);
  }
  const sortType = 'Hour';
  const res = dataSort(_rawData.data.data, sortType);
  const yAxis = [];
  const maxVal = {};
  const tempTimeData = {};
  let colorKey = 0;
  let offset = 0;
  let totalVal = 0;
  const series = [];
  const colors = ['#5470C6', '#91CC75', '#EE6666'];

  res.forEach((item) => {
    if (!tempTimeData[item.SENSOR]) {
      tempTimeData[item.SENSOR] = {};
    }
    const date = setMinuteLable(item);
    tempTimeData[item.SENSOR][date] = item;

    Object.keys(measureList[item.SENSOR]).forEach((k) => {
      // eslint-disable-next-line radix
      const tmp1 = parseInt(item[measureList[item.SENSOR][k].value]);
      if (!maxVal[measureList[item.SENSOR][k].value]) {
        maxVal[measureList[item.SENSOR][k].value] = 0;
      }

      if (tmp1 > maxVal[measureList[item.SENSOR][k].value]) {
        maxVal[measureList[item.SENSOR][k].value] = tmp1;
      }
    });
  });

  const minute = 0;
  const hour = 5;
  const color = common.colors();
  const timeField = getMinuteTimeField(hour, minute, stepTime);
  const measures = [];

  Object.keys(measureList).forEach((k) => {
    Object.keys(measureList[k]).forEach((k1) => {
      const tempData = [];
      for (let i = hour; i <= 20; i += 1) {
        for (let x = minute; x < 60; x += stepTime) {
          let h;
          let
            m;
          // eslint-disable-next-line no-unused-expressions
          i < 10 ? h = `0${i}` : h = `${i}`;
          // eslint-disable-next-line no-unused-expressions
          x < 10 ? m = `0${x}` : m = `${x}`;
          const time = `${h}:${m}`;

          let vals = 0;

          if (k in tempTimeData) {
            if (time in tempTimeData[k]) {
              if (measureList[k][k1].value in tempTimeData[k][time]) {
                vals = tempTimeData[k][time][measureList[k][k1].value];
              }
            }

            tempData.push(Math.round(vals, 1));
          }
        }
      }

      const chartType = 'line';

      const obj = {
        name: `${k} ${i18n.t(measureList[k][k1].text)}(${
          measureList[k][k1].unit})`,
        data: tempData,
        type: chartType,
      };

      let position = 'left';
      if (colorKey > 0) {
        position = 'right';
        obj.yAxisIndex = colorKey;
      }

      if (colorKey > 1) {
        offset += 110;
      }

      if (measureList[k][k1].value in maxVal) {
        if (maxVal[measureList[k][k1].value] > 0) {
          totalVal = maxVal[measureList[k][k1].value] * (120 / 100);
        }
      }

      const xAxis = {
        type: 'value',
        name: `${i18n.t(measureList[k][k1].value)}(${
          measureList[k][k1].unit})`,
        min: 0,
        max: totalVal,
        offset,
        position,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[colorKey],
          },
        },
        axisLabel: {
          formatter: `{value} ${measureList[k][k1].unit}`,
        },
      };
      if (colorKey > 1) {
        xAxis.show = false;
      }

      yAxis.push(xAxis);
      series.push(obj);
      colorKey += 1;
    });
  });
  return chartOptions.multi_line_option(timeField, measures, series, color,
    yAxis);
}
export function getFarmSensorChartOpts(_rawData, stepTime, measureList) {
  if (_rawData.status !== 200 || _rawData.data.success === 'false') {
    return emptyChart('text', measureList);
  }
  const sortType = 'Hour';
  const res = dataSort(_rawData.data.data, sortType);
  const yAxis = [];
  const maxVal = {};
  const tempTimeData = {};
  let colorKey = 0;
  let offset = 0;
  let totalVal = 0;
  const series = [];
  const colors = ['#5470C6', '#91CC75', '#EE6666'];

  res.forEach((item) => {
    const date = setMinuteLable(item);
    tempTimeData[date] = item;

    Object.keys(measureList).forEach((k) => {
      // eslint-disable-next-line radix
      const tmp1 = parseInt(item[measureList[k].value]);
      if (!maxVal[measureList[k].value]) {
        maxVal[measureList[k].value] = 0;
      }

      if (tmp1 > maxVal[measureList[k].value]) {
        maxVal[measureList[k].value] = tmp1;
      }
    });
  });

  const minute = 0;
  const hour = 5;
  const color = common.colors();
  const timeField = getMinuteTimeField(hour, minute, stepTime);
  const measures = [];

  Object.keys(measureList).forEach((k) => {
    const tempData = [];
    for (let i = hour; i <= 20; i += 1) {
      for (let x = minute; x < 60; x += stepTime) {
        let h;
        let
          m;
        // eslint-disable-next-line no-unused-expressions
        i < 10 ? h = `0${i}` : h = `${i}`;
        // eslint-disable-next-line no-unused-expressions
        x < 10 ? m = `0${x}` : m = `${x}`;
        const time = `${h}:${m}`;

        let vals = 0;
        console.log('k', k);

        if (time in tempTimeData) {
          if (measureList[k].value in tempTimeData[time]) {
            vals = tempTimeData[time][measureList[k].value];
          }
        }

        tempData.push(Math.round(vals, 1));
      }
    }

    const chartType = 'line';

    const obj = {
      name: `${k} ${i18n.t(measureList[k].text)}(${
        measureList[k].unit})`,
      data: tempData,
      type: chartType,
    };

    let position = 'left';
    if (colorKey > 0) {
      position = 'right';
      obj.yAxisIndex = colorKey;
    }

    if (colorKey > 1) {
      offset += 110;
    }

    if (measureList[k].value in maxVal) {
      if (maxVal[measureList[k].value] > 0) {
        totalVal = maxVal[measureList[k].value].toFixed(2) * (120 / 100);
      }
    }

    const xAxis = {
      type: 'value',
      name: `${i18n.t(measureList[k].value)}(${
        measureList[k].unit})`,
      min: 0,
      max: helper.toFixed(totalVal, 2),
      offset,
      position,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[colorKey],
        },
      },
      axisLabel: {
        formatter: `{value} ${measureList[k].unit}`,
      },
    };
    if (colorKey > 1) {
      xAxis.show = false;
    }

    yAxis.push(xAxis);
    series.push(obj);
    colorKey += 1;
  });
  return chartOptions.multi_line_option(timeField, measures, series, color,
    yAxis);
}
