import i18n from '@/locale';
import helper from '@/api/helper';
import {
  getStringboxDataList,
} from '@/api/Request/stringbox';
import chartOptions from '../chart/chart_options';
import request from '../utils';

const heatmapWeeklyHour = (formdata, measureSelect, stringboxList, stringboxNameList) => new Promise((resolve) => {
  const xAxisData = [];

  for (let i = 5; i < 21; i++) {
    for (let x = 0; x < 60; x += 10) {
      let h;
      let
        m;
      i < 10 ? h = `0${i}` : h = `${i}`;
      x < 10 ? m = `0${x}` : m = `${x}`;
      const time = `${h}:${m}`;

      xAxisData.push(time);
    }
  }

  const yAxisDatas_label = stringboxNameList;
  let hourData = [];
  let max = 10;

  request.Api('/api/stringbox/list', formdata, 'post').then((res) => {
    if (res.data.success === 'true') {
      Object.keys(res.data.data).forEach((key) => {
        let tmpData = [];

        let val = res.data.data[key][measureSelect];
        const {
          STRINGBOX,
        } = res.data.data[key];

        val = helper.show_float_val(val);

        if (max < val) {
          max = val;
        }

        let t_val = '';
        if (val > 0) {
          t_val = val;
        }

        const hour = parseInt(res.data.data[key].Hour);
        const minute = parseInt(res.data.data[key].MinuteGrup);

        let h;
        let m;
        hour < 10 ? h = `0${hour}` : h = `${hour}`;
        minute < 10 ? m = `0${minute}` : m = `${minute}`;
        const time = `${h}:${m}`;

        tmpData = [stringboxList[STRINGBOX].STRINGBOXNAME, time, t_val];
        hourData.push(tmpData);
      });
    }

    hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);
    console.log(hourData);

    const min = 0;
    const series = [{
      name: i18n.t(measureSelect),
      type: 'heatmap',
      data: hourData,

      label: {
        show: false,
        fontSize: 10,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          color: {
            type: 'radial',
            x: 0.4,
            y: 0.3,
            r: 1,
            colorStops: [{
              offset: 0,
              color: 'rgb(251, 118, 123)',
            },
            {
              offset: 1,
              color: 'rgb(204, 46, 72)',
            },
            ],
          },

        },
      },
    }];

    const grid = {
      height: '85%',
      top: '5%',
      width: '70%',
      right: '6%',

    };
    const tooltip = {
      position: 'right',
    };

    const opt = chartOptions.echartHeatmap_Options(
      series,
      xAxisData,
      yAxisDatas_label,
      min,
      max,
      grid,
      tooltip,
    );

    resolve(opt);
  });
});

const heatmapcomparisonHour = (res, dcCurrentMeasures, combinerBox, santral, stepTime) => new Promise((
  resolve,
) => {
  const xAxisData = [];

  for (let i = 5; i < 21; i++) {
    for (let x = 0; x < 60; x += stepTime) {
      let h;
      let
        m;
      i < 10 ? h = `0${i}` : h = `${i}`;
      x < 10 ? m = `0${x}` : m = `${x}`;
      const time = `${h}:${m}`;

      xAxisData.push(time);
    }
  }

  const yAxisDatas_label = [];
  let hourData = [];
  let max = 10;

  Object.keys(dcCurrentMeasures).forEach((k) => {
    if (yAxisDatas_label.indexOf(k) < 0) {
      yAxisDatas_label.push(dcCurrentMeasures[k].text);
    }
    if (res.data.success == 'true') {
      Object.keys(res.data.data).forEach((key) => {
        let tmpData = [];

        let val = res.data.data[key][dcCurrentMeasures[k].value];

        val = helper.show_float_val(val);

        if (max < val) {
          max = val;
        }

        let t_val = '';
        if (val > 0) {
          t_val = val;
        }

        const hour = parseInt(res.data.data[key].Hour);
        const minute = parseInt(res.data.data[key].MinuteGrup);
        let h; let
          m;

        hour < 10 ? h = `0${hour}` : h = `${hour}`;
        minute < 10 ? m = `0${minute}` : m = `${minute}`;
        const time = `${h}:${m}`;
        tmpData = [dcCurrentMeasures[k].text, time, t_val];
        hourData.push(tmpData);
      });
    }
  });

  hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);

  const min = 0;
  const series = [{
    name: combinerBox,
    type: 'heatmap',
    data: hourData,
    label: {
      show: false,
      fontSize: 10,
      color: 'black',

    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  }];

  const grid = {
    height: '85%',
    top: '5%',
    width: '85%',
    right: '6%',

  };
  const tooltip = {
    position: 'right',
    formatter: (params) => `${santral} <br> ${params.seriesName} <br> ${params.value[1]}<br>`
          + `${params.value[0]}: ${params.value[2]} (A)`,
  };

  const opt = chartOptions.echartHeatmap_Options(
    series,
    xAxisData,
    yAxisDatas_label,
    min,
    max,
    grid,
    tooltip,
  );

  resolve(opt);
});

const heatmapStatus = (formdata, inverterInfo) => new Promise((resolve) => {
  request.Api('/api/stringbox/status', formdata, 'post').then((res) => {
    const _status_list = {};
    Object.keys(res.data.data).forEach((key) => {
      if (!_status_list.hasOwnProperty(res.data.data[key].santral)) {
        _status_list[res.data.data[key].santral] = [];
      }
      _status_list[res.data.data[key].santral].push(res.data.data[key]);
    });
    resolve(_status_list);
  });
});

const heatmapDaily = (formdata, measureSelect, stringboxNameList) => new Promise((resolve,
  reject) => {
  const url = '/api/stringbox/daily';

  const tempData = request.Api(url, formdata, 'post');
  tempData.then((_rawData) => {
    console.log(_rawData);
    let max = 10;
    // let measureSelect = "MPPT1PanelVoltageProductivity";

    const tempTimeData = {};
    let timeList = [];
    const xAxisData = [];

    _rawData.data.data.forEach((item) => {
      console.log(item);
      let mm;
      let
        dd;
      let tmpData = [];
      item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
      item.Day < 10 ? dd = `0${item.Day}` : dd = `${item.Day}`;

      const date = `${item.Year}-${mm}-${dd}`;
      const kk = `${item.Year}-${item.Month}`;

      if (xAxisData.indexOf(date) == -1) {
        xAxisData.push(date);
      }
      const {
        STRINGBOX,
      } = item;

      let val = item[measureSelect];

      val = helper.show_float_val(val);

      if (max < val) {
        max = val;
      }
      console.log(max);
      let t_val = '';
      if (val > 0) {
        t_val = val;
      }
      console.log(t_val);

      if (!tempTimeData[kk]) {
        tempTimeData[kk] = {};
      }
      if (!tempTimeData[kk][item.Day]) {
        tempTimeData[kk][item.Day] = {};
      }
      item.date = date;
      tempTimeData[kk][item.Day] = item;

      tmpData = [STRINGBOX, date, t_val];
      timeList.push(tmpData);
    });
    console.log(tempTimeData);

    const yAxisDatas_label = stringboxNameList;

    timeList = timeList.map((item) => [item[1], item[0], item[2] || '-']);
    console.log(timeList);
    console.log(xAxisData);
    console.log(yAxisDatas_label);

    const min = 0;
    const series = [{
      name: i18n.t(measureSelect),
      type: 'heatmap',
      data: timeList,
      label: {
        show: false,
        fontSize: 10,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    }];

    const grid = {
      height: '85%',
      top: '5%',
      width: '70%',
      right: '6%',

    };
    const tooltip = {
      position: 'right',
    };

    const opt = chartOptions.echartHeatmap_Options(
      series,
      xAxisData,
      yAxisDatas_label,
      min,
      max,
      grid,
      tooltip,
    );

    resolve(opt);
  });
});

const heatmapDailyLimit = (formdata, measureSelect, stringboxNameList, santralModel) => new Promise(
  (resolve, reject) => {
    const url = '/api/stringbox/daily';

    const tempData = request.Api(url, formdata, 'post');
    tempData.then((_rawData) => {
      let max = 10;

      const tempTimeData = {};
      const timeList = {};
      const xAxisData = [];

      _rawData.data.data.forEach((item) => {
        santralModel.forEach((santral) => {
          if (!timeList[santral]) {
            timeList[santral] = [];
          }

          if (item.santral == santral) {
            let mm;
            let
              dd;
            let tmpData = [];
            item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
            item.Day < 10 ? dd = `0${item.Day}` : dd = `${item.Day}`;

            const date = `${item.Year}-${mm}-${dd}`;
            const kk = `${item.Year}-${item.Month}`;

            if (xAxisData.indexOf(date) == -1) {
              xAxisData.push(date);
            }
            const STRINGBOX = stringboxNameList[item.STRINGBOX];

            let val = item[measureSelect];

            val = helper.show_float_val(val);
            if (max < val) {
              max = val;
            }

            let t_val = '';
            if (val > 0) {
              t_val = val;
            }

            if (!tempTimeData[santral]) {
              tempTimeData[santral] = {};
            }

            if (!tempTimeData[santral][kk]) {
              tempTimeData[santral][kk] = {};
            }
            if (!tempTimeData[santral][kk][item.Day]) {
              tempTimeData[santral][kk][item.Day] = {};
            }
            item.date = date;
            tempTimeData[santral][kk][item.Day] = item;

            tmpData = [STRINGBOX, date, t_val];
            timeList[santral].push(tmpData);
          }
        });
      });

      const yAxisDatas_label = Object.values(stringboxNameList);
      const opt = {};
      santralModel.forEach((santral) => {
        timeList[santral] = timeList[santral].map((item) => [item[1], item[0], item[2]
          || '-',
        ]);

        const min = 0;
        const series = [{
          name: i18n.t(measureSelect),
          type: 'heatmap',
          data: timeList[santral],
          label: {
            show: false,
            fontSize: 10,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }];

        const grid = {
          height: '85%',
          top: '5%',
          width: '75%',
          right: '10%',

        };
        const tooltip = {
          position: 'top',
          formatter(p) {
            return `${p.data[1]}<br>${p.seriesName}<br>${p.data[0]} : ${p
              .data[2]}%`;
          },

        };

        opt[santral] = chartOptions.echartHeatmap_Options(
          series,
          xAxisData,
          yAxisDatas_label,
          min,
          max,
          grid,
          tooltip,
        );
      });

      resolve(opt);
    });
  },
);

const heatmapWeeklyHourMean = (res, measureSelect, stringboxList, steptime) => new Promise((resolve) => {
  const xAxisData = [];

  const st = steptime;
  for (let i = 5; i < 21; i++) {
    for (let x = 0; x < 60; x += st) {
      let h;
      let
        m;
      i < 10 ? h = `0${i}` : h = `${i}`;
      x < 10 ? m = `0${x}` : m = `${x}`;
      const time = `${h}:${m}`;

      xAxisData.push(time);
    }
  }

  const yAxisDatas_label = Object.values(stringboxList);
  let hourData = [];
  let max = 10;

  if (res.data.success === 'true') {
    const tmpGroupData = {};
    const totalGroupData = {};
    Object.keys(res.data.data).forEach((key) => {
      const hour = parseInt(res.data.data[key].Hour);
      const minute = parseInt(res.data.data[key].MinuteGrup);

      let h;
      let m;

      hour < 10 ? h = `0${hour}` : h = `${hour}`;
      minute < 10 ? m = `0${minute}` : m = `${minute}`;
      const time = `${h}:${m}`;

      if (tmpGroupData[time] === undefined) {
        tmpGroupData[time] = [];
        totalGroupData[time] = {
          count: 0,
          index: 0,
        };
      }
      let val = 0;
      if (helper.isObjectKeysExist(res.data.data[key], measureSelect.value)) {
        val = res.data.data[key][measureSelect.value];
      }

      val = helper.show_float_val(val);

      if (max < val) {
        max = val;
      }

      if (val > 0) {
        totalGroupData[time].count += val;
        totalGroupData[time].index += 1;
      }

      tmpGroupData[time].push(res.data.data[key]);
    });

    let tmpData = [];
    Object.keys(tmpGroupData).forEach((k) => {
      if (totalGroupData[k].count > 0 && totalGroupData[k].index > 0) {
        totalGroupData[k].mean = totalGroupData[k].count / totalGroupData[k].index;
      }

      if (typeof temps === 'number') {
        totalGroupData[k].mean = parseFloat(totalGroupData[k].mean.toFixed(1));
      } else {
        totalGroupData[k].mean = 0;
      }

      Object.keys(tmpGroupData[k]).forEach((i) => {
        const val = tmpGroupData[k][i][measureSelect.value];

        const {
          STRINGBOX,
        } = tmpGroupData[k][i];

        const vals = helper.show_float_val(val);
        tmpData = [stringboxList[STRINGBOX], k, vals];
        hourData.push(tmpData);
      });
    });

    hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);
  }

  let name = measureSelect.text;
  if (measureSelect.unit !== '') {
    name = `${measureSelect.text} (${measureSelect.unit})`;
  }
  const min = 0;
  const series = [{
    name,
    type: 'heatmap',
    data: hourData,
    label: {
      show: false,
      fontSize: 10,
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  }];

  const grid = {
    height: '85%',
    top: '5%',
    width: '70%',
    right: '6%',

  };
  const tooltip = {
    position: 'right',
  };

  const opt = chartOptions.echartHeatmap_Options(
    series,
    xAxisData,
    yAxisDatas_label,
    min,
    max,
    grid,
    tooltip,
  );

  resolve(opt);
});

const heatmapWeeklyHourMeans_combiner_diff = (formdata, measureSelect) => new Promise((resolve) => {
  const hourData = [];
  let max = 10;

  request.Api('/api/stringbox/list', formdata, 'post').then((res) => {
    if (res.data.success == 'true') {
      const tmpGroupData = {};
      const totalGroupData = {};
      Object.keys(res.data.data).forEach((key) => {
        const hour = parseInt(res.data.data[key].Hour);

        if (hour > 5 && hour < 20) {
          const minute = parseInt(res.data.data[key].MinuteGrup);

          let h;
          let m;
          hour < 10 ? h = `0${hour}` : h = `${hour}`;
          minute < 10 ? m = `0${minute}` : m = `${minute}`;

          const time = `${h}:${m}`;

          if (tmpGroupData[time] === undefined) {
            tmpGroupData[time] = [];
            totalGroupData[time] = {
              count: 0,
              index: 0,
            };
          }

          let val = res.data.data[key][measureSelect];
          val = helper.show_float_val(val);

          if (max < val) {
            max = val;
          }

          if (val > 0) {
            totalGroupData[time].count += val;
            totalGroupData[time].index += 1;
          }

          tmpGroupData[time].push(res.data.data[key]);
        }
      });

      Object.keys(tmpGroupData).forEach((k) => {
        if (totalGroupData[k].count > 0 && totalGroupData[k].index > 0) {
          totalGroupData[k].mean = totalGroupData[k].count / totalGroupData[k].index;
        }

        Object.keys(tmpGroupData[k]).forEach((i) => {
          const val = tmpGroupData[k][i][measureSelect];
          const f = totalGroupData[k].mean * (90 / 100);

          const colors = ['#23a643', '#f03a2e'];

          const obj = {};
          if (f > val) {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[1];
          } else {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[0];
          }

          obj.STRINGBOXNAME = tmpGroupData[k][i].STRINGBOXNAME;
          obj.value = val;
          obj.time = k;
          obj.mean = totalGroupData[k].mean;

          if (f > 0) {
            obj.meanval = f;
          } else {
            obj.meanval = 0;
          }

          hourData.push(obj);
        });
      });

      resolve(hourData);
    }
  });
});

const heatmapDailyMeans_string_diff = (formdata, dc_current) => new Promise((resolve) => {
  const hourData = {};
  const max = 10;

  getStringboxDataList(formdata).then((res) => {
    if (res.data.success == 'true') {
      const tmpGroupData = {};
      const totalGroupData = {};

      Object.keys(res.data.data).forEach((key) => {
        const hour = parseInt(res.data.data[key].Hour);

        if (hour > 6 && hour < 20) {
          const minute = parseInt(res.data.data[key].MinuteGrup);

          const string = res.data.data[key].STRINGBOX;

          if (tmpGroupData[string] === undefined) {
            tmpGroupData[string] = {};
            totalGroupData[string] = {};
            hourData[string] = [];
          }
          let h;
          let
            m;
          hour < 10 ? h = `0${hour}` : h = `${hour}`;
          minute < 10 ? m = `0${minute}` : m = `${minute}`;

          const time = `${h}:${m}`;
          if (totalGroupData[string][time] === undefined) {
            totalGroupData[string][time] = {
              count: 0,
              index: 0,
            };
          }

          dc_current[string].forEach((dc) => {
            if (tmpGroupData[string][dc] === undefined) {
              tmpGroupData[string][dc] = {};
            }

            if (tmpGroupData[string][dc][time] === undefined) {
              tmpGroupData[string][dc][time] = [];
            }

            let val = res.data.data[key][dc];
            // eslint-disable-next-line camelcase
            const dc_v = res.data.data[key].STRINGBOX;

            if (val === undefined) {
              val = 0;
            }

            if (val > 0) {
              totalGroupData[dc_v][time].count += val;
              totalGroupData[dc_v][time].index += 1;
            }

            if (tmpGroupData[dc_v][dc][time].length === 0) {
              tmpGroupData[dc_v][dc][time].push(res.data.data[key]);
            }
          });
        }
      });
      const colors = ['#23a643', '#f03a2e'];

      Object.keys(tmpGroupData).forEach((string) => {
        Object.keys(tmpGroupData[string]).forEach((dc) => {
          Object.keys(tmpGroupData[string][dc]).forEach((k) => {
            if (totalGroupData[string][k].count > 0 && totalGroupData[string][k]
              .index > 0) {
              totalGroupData[string][k].mean = totalGroupData[string][k].count
                / totalGroupData[string][k].index;
            } else {
              totalGroupData[string][k].mean = 0;
            }

            let val = tmpGroupData[string][dc][k][0][dc];

            val = helper.show_float_val(val);
            const obj = {};

            obj.dc_current = dc;
            obj.value = val;
            obj.time = k;
            obj.mean = totalGroupData[string][k].mean;

            // eslint-disable-next-line no-mixed-operators
            const f = totalGroupData[string][k].mean * 90 / 100;

            if (f > val) {
              // eslint-disable-next-line prefer-destructuring
              obj.color = colors[1];
            } else {
              // eslint-disable-next-line prefer-destructuring
              obj.color = colors[0];
            }

            if (val === 0) {
              // eslint-disable-next-line prefer-destructuring
              obj.color = colors[1];
            }

            hourData[string].push(obj);
          });
        });
      });

      resolve(hourData);
    }
  });
});

const heatmapCombiner_string_diff = (formdata, measureSelect, stringboxList,
  stringboxNameList, dc_current) => new Promise((resolve) => {
  console.log(dc_current);

  const hourData = {};
  const max = 10;

  request.Api('/api/stringbox/list', formdata, 'post').then((res) => {
    if (res.data.success == 'true') {
      const tmpGroupData = {};
      const totalGroupData = {};
      Object.keys(res.data.data).forEach((key) => {
        const hour = parseInt(res.data.data[key].Hour);

        if (hour > 6 && hour < 20) {
          const minute = parseInt(res.data.data[key].MinuteGrup);

          Object.keys(dc_current).forEach((st) => {
            if (tmpGroupData[st] === undefined) {
              tmpGroupData[st] = {};
              totalGroupData[st] = {};
              hourData[st] = [];
            }

            dc_current[st].forEach((dc) => {
              if (tmpGroupData[st][dc] === undefined) {
                tmpGroupData[st][dc] = {};
                totalGroupData[st][dc] = {};
              }

              let h;
              let
                m;
              hour < 10 ? h = `0${hour}` : h = `${hour}`;
              minute < 10 ? m = `0${minute}` : m = `${minute}`;

              const time = `${h}:${m}`;

              if (tmpGroupData[st][dc] === undefined) {
                tmpGroupData[st][dc] = [];
                totalGroupData[st][dc] = {
                  count: 0,
                  index: 0,
                };
              }

              let val = res.data.data[key][dc];
              const dc_v = res.data.data[key].STRINGBOX;

              if (val == undefined) {
                val = 0;
              }

              if (val > 0) {
                totalGroupData[dc_v][dc].count += val;
                totalGroupData[dc_v][dc].index += 1;
              }

              if (tmpGroupData[dc_v][dc].length == 0) {
                tmpGroupData[dc_v][dc].push(res.data.data[key]);
              }
            });
          });
        }
      });

      Object.keys(tmpGroupData).forEach((st) => {
        Object.keys(tmpGroupData[st]).forEach((dc) => {
          Object.keys(tmpGroupData[st][dc]).forEach((k) => {
            if (totalGroupData[st][dc][k].count > 0 && totalGroupData[st][dc][
              k
            ]
              .index > 0) {
              totalGroupData[st][dc][k].mean = totalGroupData[st][dc][k]
                .count
                / totalGroupData[st][dc][k]
                  .index;
            }

            Object.keys(tmpGroupData[st][dc][k]).forEach((i) => {
              let val = tmpGroupData[st][dc][k][i][dc];

              val = helper.show_float_val(val);

              const f = totalGroupData[st][dc][k].mean * 90 / 100;
              const colors = ['#23a643', '#f03a2e'];

              const obj = {};
              if (f > val) {
                obj.color = colors[1];
              } else {
                obj.color = colors[0];
              }

              obj.STRINGBOXNAME = dc;
              obj.value = val;
              obj.santral = st;

              hourData.push(obj);
            });
          });
        });
      });

      resolve(hourData);
    }
  });
});

export default {
  heatmapWeeklyHour,
  heatmapcomparisonHour,
  heatmapStatus,
  heatmapDaily,
  heatmapDailyLimit,
  heatmapWeeklyHourMean,
  heatmapWeeklyHourMeans_combiner_diff,
  heatmapDailyMeans_string_diff,
  heatmapCombiner_string_diff,

};
