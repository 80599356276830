<template>
  <div>
    <v-col class="mb-15 pb-10 col-12">
      <v-card>
        <v-col cols="12" class="pl-0 pr-0 pb-1">
          <v-row>
            <v-spacer />

            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Tarih"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="startDate" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-select
                v-model="measureSelect"
                :items="stringdata.measures"
                label=""
              />
            </v-col>

            <v-col cols="3" sm="1" md="1" class="pr-6">
              <v-btn color="primary" @click="santralList"> Filtrele </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <template v-for="santral in stringdata.santralList">
            <div class="col-12 text-center">
              <v-divider />
              {{ santral }}
            </div>

            <div :id="'d3jstotaldccurrent' + santral" class="pb-2 svgclass" />
          </template>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import * as Heatmap from "@/api/CombinerBox/Heatmap";
import * as d3 from "d3";
import i18n from "@/locale";
import moment from "moment";
import helper from "@/api/helper";
import {
  d3jsChartComponent,
  d3jsChartToolTip,
} from "@/api/Components/d3jsComponent";

export default {
  props: ["stringdata"],

  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format("YYYY-MM-DD"),
      finishDate: moment().format("YYYY-MM-DD"),
      menu: false,
      modal: false,
      menu2: false,

      measureSelect: "DCCurrent",
      componentKey: 1,
      title: "",
      santralModel: "",
      inverterList: [],
      height: "",
      HeatmapData: [],
    };
  },
  computed: {},
  watch: {
    stringdata: {
      handler() {
        this.santralList();
      },
      deep: true,
    },
  },

  mounted() {},
  methods: {
    santralList() {
      const { santralStringboxData } = this.stringdata;

      Object.keys(santralStringboxData).forEach((santral) => {
        const stringList = Object.keys(santralStringboxData[santral]);
        this.heatmaps(stringList, santral);
      });
    },
    heatmaps(stringList, santral) {
      const chartId = `d3jstotaldccurrent${santral}`;
      const elemnet = document.getElementById(chartId);

      if (elemnet !== null) {
        elemnet.innerHTML = "";
      }
      const formdata = {
        condiniton: {
          santral,
          startDate: this.startDate,
          finishDate: this.startDate,
          measures: this.measureSelect,
          STRINGBOX: stringList,
          maxcount: 3000,
          MinuteGrup: [0, 10, 20, 30, 40, 50],
        },
      };

      if (this.stringdata.prefix) {
        const { prefix, companyCode } = this.stringdata;
        formdata.condiniton.prefix = prefix;
        formdata.condiniton.companyCode = companyCode;
      }

      const temp = Heatmap.default.heatmapWeeklyHourMeans_combiner_diff(
        formdata,
        this.measureSelect
      );
      temp.then((data) => {
        const margin = {
          top: 10,
          right: 150,
          bottom: 0,
          left: 150,
        };
        let itemlenght = 5;
        if (stringList.length > 0) {
          itemlenght = stringList.length;
        }
        const { windowsWidth } = this.stringdata;
        const extraWidth = 200;

        const width = windowsWidth - extraWidth - margin.left - margin.right;
        const height = itemlenght * 15 + 20;
        let unit = "";
        this.stringdata.measures.forEach((measure) => {
          if (this.measureSelect === measure.value) {
            unit = measure.unit;
          }
        });
        const parameter = {
          width,
          height,
          transform: `translate(${margin.left},${margin.top})`,
          chartId,
          chartXkey: "time",
          chartYkey: "STRINGBOXNAME",
          measureSelect: this.measureSelect,
          unit,
          startDate: this.startDate,
        };

        // eslint-disable-next-line no-underscore-dangle
        const _t = d3jsChartToolTip(parameter);
        const mousemove = function (d) {
          const value = helper.show_float_val(d.value);
          let mean = 0;
          if (!helper.is_Empty(d.mean)) {
            mean = d.mean.toFixed(2);
          }

          _t.toolTip
            .html(
              `${parameter.startDate} ${d[parameter.chartXkey]} <br> ${
                d[parameter.chartYkey]
              }<br> ${parameter.measureSelect} : ${value} ${
                parameter.unit
              } <br> Ortalama : ${mean} ${parameter.unit}`,
            )
            .style('z-index', '9999')
            .style('left', `${d3.mouse(this)[0] - 80}px`);
        };
        d3jsChartComponent(parameter, data, mousemove, _t.toolTip);
      });
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 100%;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 180px;
  min-height: 70px;
  padding: 2px;
  font: 16px sans-serif;
  border: 1px solid black;
  border-radius: 8px;
  pointer-events: none; /* keep the mouseover when over the tooltip */
}

.subtitle {
  font-family: sans-serif;
  font-size: 20px;
}

.legendPointText {
  font-size: 10px;
}

#footer {
  color: black;
  text-align: center;
}
#footer a {
  color: blue;
}
</style>
