<template>
  <InverterFarmCompariton :inverterdata="inverterdata" />
</template>

<script>
import InverterFarmCompariton from "@/views/components/Inverter/InverterFarmCompariton.vue";
import { getInverterList } from "@/api/Request/inverter";
import helper from "@/api/helper";

console.log("SelectedFarmInverter");
export default {
  components: {
    InverterFarmCompariton
  },
  props: ["farmdata"],
  data() {
    return {
      inverterdata: {}
    };
  },
  computed: {},
  watch: {
    farmdata: {
      handler() {
        this.selectedInverter();
      },
      deep: true
    }
  },

  mounted() {
    if (Object.keys(this.farmdata).length > 0) {
      this.selectedInverter();
    }
  },
  methods: {
    selectedInverter() {
      const payload = {
        condiniton: {
          prefix: this.farmdata.prefix,
          companyCode: this.farmdata.companyCode
        }
      };

      this.getInverterData(payload);
    },
    getInverterData(payload) {
      const windowsWidth = document.documentElement.clientWidth - 270;
      getInverterList(payload).then(res => {
        if (res.data.success === "true") {
          if (!helper.is_Empty(res.data.data)) {
            // context.commit('SET_INVERTERDATA', res.data.data);
            const temp = {};
            const tempMeasures = [];
            const measuresList = [];
            const santralList = [];
            // eslint-disable-next-line no-underscore-dangle
            const _t = ["sum", "avg", "1"];
            Object.keys(res.data.data).forEach(i => {
              const val = res.data.data[i];

              const {
                santral,
                INVERTER,
                // eslint-disable-next-line camelcase
                general_measure_list
              } = val;

              if (santralList.indexOf(santral) === -1) {
                santralList.push(santral);
              }
              if (!helper.is_Empty(INVERTER)) {
                if (helper.is_Empty(temp[santral])) {
                  temp[santral] = {};
                }
                if (helper.is_Empty(temp[santral][INVERTER])) {
                  temp[santral][INVERTER] = {};
                }
                temp[santral][INVERTER] = val;

                if (!helper.is_Empty(general_measure_list)) {
                  // eslint-disable-next-line no-shadow
                  Object.keys(general_measure_list).forEach(i => {
                    if (
                      measuresList.indexOf(general_measure_list[i].value) === -1 &&
                      _t.indexOf(general_measure_list[i].formuletype) > -1
                    ) {
                      tempMeasures.push(general_measure_list[i]);
                      measuresList.push(general_measure_list[i].value);
                    }
                  });
                }
              }
            });
            this.inverterdata = {
              measures: tempMeasures,
              santralInverterData: temp,
              santralList,
              prefix: this.farmdata.prefix,
              companyCode: this.farmdata.companyCode,
              windowsWidth
            };
          }
        }
      });
    }
  }
};
</script>
