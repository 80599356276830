<template>
  <v-row dense class="panel-group">
    <div class="col-12 text-center d-flex">
      <div
        v-for="(item, idx) in rest"
        :key="idx"
        class="col-2 pr-0"
        transition="slide-x-transition"
      >
        <panel-group-item :item="item" />
      </div>
    </div>
  </v-row>
</template>
<script>
import PanelGroupItem from "./PanelGroupItem.vue";

export default {
  name: "PanelGroup",
  components: {
    PanelGroupItem,
  },
  props: ["rest"],
  data() {
    return {
      items: [
        {
          icon: "mdi-solar-power",
          title: "New Visits",
          value: 10,
          color: "orange",
        },
        {
          icon: "mdi-solar-power",
          title: "New Visits",
          value: 10,
          color: "orange",
        },
        {
          icon: "mdi-solar-panel",
          title: "New Visits",
          value: 10,
          color: "orange",
        },
      ],
    };
  },
  watch: {},
  created() {},
  methods: {},
};
</script>
